<template>
  <div>
    <b-card>
      <b-row class="mb-1">
        <b-col>
          <!-- start of remove form-builder for dropdown changes -->
          <ValidationObserver
            v-slot="{ handleSubmit }"
            slim
          >
            <b-form
              class="my-8"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <div class="position-relative">
                    <b-form-group
                      label="Waybill Number"
                      label-for="waybill"
                    >
                      <b-form-input
                        id="waybill"
                        ref="waybill"
                        v-model="formData.waybill_number"
                        type="text"
                        autocomplete="nope"
                        :disabled="loadingResponse"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Reason"
                    label-for="reason"
                  >
                    <v-select
                      v-model="formData.reason"
                      :options="reasonOptions"
                      :reduce="option => option.name"
                      label="name"
                      placeholder="Select Reason"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <!-- submit and reset -->
                <b-col
                  cols="12"
                  md="3"
                  class="d-flex d-md-block justify-content-end align-items-end"
                >
                  <b-button
                    type="submit"
                    class="vh-5 mt-2 ml-1 ml-md-1"
                    variant="primary"
                    :disabled="!(formData.waybill_number && formData.reason) || loadingResponse"
                  >
                    {{ loadingResponse ? 'Submitting...' : 'Submit' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
          <!-- end of remove form-builder for dropdown changes -->
        </b-col>
      </b-row>
    </b-card>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row class="mb-1">
        <b-col
          md="4"
          sm="2"
        >
          <b-form-group
            label="Confirmed Date"
            label-for="basicInput"
          >
            <div class="position-relative">
              <flat-pickr
                id="v-ref-no"
                v-model="filters['status_date']"
                :config="{mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                class="form-control"
                placeholder="Search"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <b-row class="mb-1 mr-1 ml-1">
        <b-col
          class="d-flex justify-content-start align-items-center"
          md="6"
        >
          <b-button
            :disabled="selectedWaybillArray.length === 0"
            class="mt-2 mr-1 custom-button-color"
            @click="onClickBulkAction()"
          >
            Bulk Submit
          </b-button>
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          cols="12"
          md="6"
        >
          <TableButtons
            class="mt-2"
            :items="rows"
            :json_fieldz="json_fields"
            :name="`Cancelled - Page ${page}.xls`"
            :bulkname="`Cancelled.xls`"
            :fetch="getOrderListNoPagination"
            @refresh="onClickRefresh"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1 mr-1 ml-1">
        <b-col
          class="d-flex justify-content-start align-items-center"
          md="6"
        >
          <div class="font-weight-bolder">
            {{ `${selectedWaybillArray.length} record${selectedWaybillArray.length === 1 ? '':'s'}` }} selected
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              :per-page="perPage"
              :fields="columns"
              :items="rows"
              hover
              responsive
              striped
              show-empty
              sticky-header="100vh"
              class="hide-vertical-scroll"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #head(id)>
                <div class="d-flex align-items-start">
                  <div>
                    <b-form-checkbox
                      id="selectAllCheckbox"
                      v-model="selectAllCheckbox"
                      name="selectAllCheckbox"
                      @change="onClickSelectAllCheckbox()"
                    />
                  </div>
                </div>
              </template>
              <template #cell(id)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedWaybillArray"
                        :value="data.item.waybill_number"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(waybill_number)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.customer_name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                      @click="onClickViewOrder(data.item.id)"
                    >
                      {{ data.value }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder ml-1">
                      {{ data.item.customer_name }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2  ml-1">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2  ml-1"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ data.item.customer_address }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(origin_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder ml-1">
                      CITY - {{ data.item.origin_city.name }}
                    </div>
                    <div class="font-small-2 ml-1">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(destination_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder ml-1">
                      CITY - {{ data.item.destination_city.name }}
                      <!--                    </b-badge>-->
                    </div>
                    <div class="font-small-2 ml-1">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(status.key)="data">
                <order-status-badge
                  :status-key="data.value"
                />
              </template>
              <template #cell(cod)="data">
                {{ __numberWithCommas(data.value, true) }}
              </template>
              <template #cell(collected_cod)="data">
                {{ __numberWithCommas(data.value, true) }}
              </template>
              <template #cell(delivery_charge)="data">
                {{ __numberWithCommas(data.value, true) }}
              </template>
              <template #cell(weight)="data">
                {{ (data.value).toLocaleString() }}
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickViewOrder(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View Details
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-125 mr-1"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1 pagination-text mt-1 mt-md-0">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <view-order-modal ref="viewOrderModal" />
    <bulk-action-modal
      ref="bulkActionModal"
      :form_structure="formStructure"
      :status_key="status_key"
      :selected_waybill_array="selectedWaybillArray"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BFormGroup,
  BPagination,
  BOverlay,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import { avatarText } from '@core/utils/filter'
import displayAmountWithCurrency from '@core/mixins/currency'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import ViewOrderModal from '@/components/orders/ViewOrderModal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import BulkActionModal from './bulk-actions/BulkActionModal.vue'

const orderRepository = RepositoryFactory.get('order')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  name: 'KeyThree',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    flatPickr,
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BFormGroup,
    BPagination,
    OrderStatusBadge,
    BOverlay,
    BulkActionModal,
    BFormCheckbox,
    BCardActions,
    vSelect,
    BDropdown,
    BDropdownItem,
    ViewOrderModal,
    FilterButtons,
    BFormInput,
    BForm,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, displayAmountWithCurrency],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    status_key: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      avatarText,
      dataLoading: false,
      rows: [],
      columns: [
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'waybill_number',
          label: 'WayBill Number',
          sortable: true,
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'origin_warehouse',
          label: 'Origin',
          sortable: true,
        },
        {
          key: 'destination_warehouse',
          label: 'Destination',
          sortable: true,
        },
        {
          key: 'customer_email',
          label: 'Customer Contact',
          sortable: true,
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'collected_cod',
          label: 'Collected Cod',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'weight',
          label: 'Weight',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Origin City': {
          field: 'origin_city.name',
          callback: value => `"${value}"`,
        },
        'Origin Warehouse': {
          field: 'origin_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Destination City': {
          field: 'destination_city.name',
          callback: value => `"${value}"`,
        },
        'Destination Warehouse': {
          field: 'destination_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: {
          field: 'cod',
          callback: value => `"${value}"`,
        },
        'Delivery Charge': {
          field: 'delivery_charge',
          callback: value => `"${value}"`,
        },
        'Collected Cod': 'collected_cod',
        Weight: 'weight',
        Status: 'status.name',
      },
      perPage: 20,
      pageOptions: [10, 20, 50],
      filters: {},
      total: 0,
      loadingResponse: false,
      meta: {},
      page: 1,
      formData: {},
      formStructure: [
        {
          type: 'input',
          placeholder: '',
          inputType: 'text',
          title: 'Waybill Number',
          value: 'waybill_number',
          class: 'col-3',
          validation: 'required',
        },
        {
          type: 'select',
          placeholder: '',
          title: 'Reason',
          value: 'reason',
          class: 'col-3',
          fetch: ResourceRepository.getReasonListForDropDown(this.status_key),
          fetchFilter: reason => ({ label: `${reason.name}`, value: reason.name }),
          multiple: false,
        },
      ],
      reasonOptions: [],
      selectedWaybillArray: [],
      waybillData: [],
      inputDisabled: true,
      orderSettings: {},
      settingWaybillLength: 0,
      selectAllCheckbox: false,
    }
  },
  watch: {
    page() {
      this.getOrderList()
    },
    perPage() {
      this.getOrderList()
    },
  },
  loadingResponse(newValue) {
    /* eslint no-underscore-dangle: 0 */
    this.__toggleDisableInput('Waybill Number', newValue)
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getOrderList()
    this.inputDisabled = true
    this.getReasonList()
    this.$watch(
      'formData.waybill_number',
      async val => {
        if (val) {
          if (val.length === this.settingWaybillLength) {
            await this.getOrderDetails(val)
          }
        }
      },
    )
  },
  methods: {
    async getOrderDetails(waybill) {
      try {
        const { data } = (await orderRepository.getOrderDetails(waybill)).data
        // eslint-disable-next-line no-empty
        if (data.waybill_number === waybill) {
        } else {
          this.showErrorMessage('Invalid waybill number!!')
        }
      } catch (e) {
        this.showErrorMessage('Invalid waybill number!!')
      }
    },
    async getOrderList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await orderRepository.getOrderListByKey(this.status_key, this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.dataLoading = false
    },
    async getOrderListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await orderRepository.getOrderListByKeyNoPagination(this.status_key, this.filterQuery))
        return data.data
        // eslint-disable-next-line no-empty
      } catch (e) {
        return []
      }
    },
    async getReasonList() {
      try {
        this.reasonOptions = localStorage.getItem('reason_list') ? JSON.parse(localStorage.getItem('reason_list')) : (await ResourceRepository.getReasonListForDropDown(this.status_key || 'key_3')).data.data
        if (!localStorage.getItem('reason_list')) localStorage.setItem('reason_list', JSON.stringify(this.reasonOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onSubmit() {
      this.loadingResponse = true
      try {
        const payload = {
          waybill_numbers: [],
        }
        payload.waybill_numbers.push(this.formData.waybill_number)
        payload.status_key = this.status_key
        payload.other_details = {
          failed_reason: this.formData.reason,
        }

        await orderRepository.updateOrderStatus(payload)
        this.loadingResponse = false
        await this.getOrderList()
        this.waybillData = []
        // await this.getOrderList()
        this.showSuccessMessage('Order updated successfully')
        this.clearFormData()
      } catch (e) {
        this.convertAndNotifyError(e)
        this.clearFormData()
      }
    },
    clearFormData() {
      this.$set(this.formData, 'waybill_number', '')
      this.focusToWaybill()
    },
    focusToWaybill() {
      this.loadingResponse = false
      this.$nextTick(() => {
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.waybill.focus()
      })
    },
    applyFilters() {
      this.getOrderList()
      this.onClickDeselectAll()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getOrderList()
      })
    },
    onClickBulkAction() {
      this.$refs.bulkActionModal.openModal()
    },
    onClickSelectAll() {
      this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
    },
    onClickDeselectAll() {
      this.selectedWaybillArray = []
    },
    changePage(value) {
      this.perPage = value
    },
    handleChangePage(page) {
      this.page = page
      this.getOrderList()
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    onClickRefresh() {
      this.getOrderList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
      } else {
        this.selectedWaybillArray = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
@import '../../@core/scss/button-color.scss';
</style>
