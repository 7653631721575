<template>
  <div>
    <b-row>
      <b-col
        md="6"
        sm="4"
        class="my-1 align-items-start"
      >
        <b-card-text />
        <v-select
          v-model="selectedKey"
          :reduce="option => option.value"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="text"
          placeholder="Select Required Operation"
          :options="statuses"
        />
      </b-col>
      <b-col
        md="3"
        sm="2"
        class="my-1"
      />
      <b-col
        md="3"
        sm="2"
        class="my-1"
      />
    </b-row>
    <div v-if="selectedKey === 'key_2'">
      <key-two :status_key="'key_2'" />
    </div>
    <div v-if="selectedKey === 'key_3'">
      <key-three :status_key="'key_3'" />
    </div>
    <div v-if="selectedKey === 'key_21'">
      <key-twenty-one :status_key="'key_21'" />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions,camelcase
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import KeyTwo from '../../components/status-flow/KeyTwo.vue'
import KeyThree from '../../components/status-flow/KeyThree.vue'
import KeyTwentyOne from '../../components/status-flow/KeyTwentyOne.vue'

const orderRepository = RepositoryFactory.get('order')

export default {
  directives: {
    Ripple,
  },
  components: {
    KeyTwo,
    KeyThree,
    KeyTwentyOne,
    BRow,
    BCol,
    vSelect,
  },
  data() {
    return {
      operationSelected: [],
      statuses: [],
      selectedKey: 'key_2',
    }
  },
  mounted() {
    this.getStatusList()
  },
  methods: {
    async getStatusList() {
      const data = localStorage.getItem('merchant_operations') ? JSON.parse(localStorage.getItem('merchant_operations')) : (await orderRepository.getStatusList()).data.data
      if (!localStorage.getItem('merchant_operations')) localStorage.setItem('merchant_operations', JSON.stringify(data))
      this.statuses = data.map(n => ({ text: n.name, value: n.key }))
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
</style>
